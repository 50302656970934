import {
  PreloadAllModules,
  provideRouter,
  UrlSerializer,
  withComponentInputBinding,
  withPreloading
} from '@angular/router'
import AppRoutes from './app.routes'
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, isDevMode, provideZoneChangeDetection } from '@angular/core'
import { provideServiceWorker } from '@angular/service-worker'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { ErrorInterceptor } from './_shared/services/interceptors/ErrorInterceptor'
import { RequestInterceptor } from './_shared/services/interceptors/RequestInterceptor'
import { LowerCaseUrlSerializer } from './_shared/classes/lower-case-url-serializer'
import { AppErrorHandler } from './app-error-handler'
import { fetchTranslation } from './_shared/functions/translation'

function onAppInit(): () => Promise<void> {
  return fetchTranslation()
}

export default {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(AppRoutes, withPreloading(PreloadAllModules), withComponentInputBinding()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode()
    }),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true
    }
  ]
} as ApplicationConfig
